import React, { useLayoutEffect } from "react";
import Fade from "react-reveal/Fade";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Image from "../components/Image";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../components/Header";
import Loadable from "@loadable/component";
import "../assets/sass/faq.scss";

const Footer = Loadable(() => import("../components/Footer/index.js"));

const IndexPage = () => {
    const expandAccordion = (ev) => {

        if(ev.target.parentElement.className.indexOf("open") > -1){
            ev.target.parentElement.className += " closed";
            setTimeout(()=>{
                ev.target.parentElement.className = ev.target.parentElement.className.replace(
                  " open",
                  ""
                );
            },500);
            
        } else {
            ev.target.parentElement.className += " open";
            ev.target.parentElement.className = ev.target.parentElement.className.replace(
              "closed", ""
            );
        }
    }
    useLayoutEffect(() => {
      let elems = document
        .querySelectorAll(".c-accordion__title")
        .forEach((e) => e.addEventListener("click", expandAccordion, false));
      if (document.URL.split("#")[1]){
        document.getElementById(document.URL.split("#")[1]).className += " open";
      }
        return () => {
          document
            .querySelectorAll(".c-accordion__title")
            .forEach((e) => e.removeEventListener("click", expandAccordion));
        };
    }, []);
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(slug: { eq: "faq-internal" }) {
          id
          content
        }
      }
    `
  );
  const {
    content
  } = data.wpPage;
  return (
    <Layout>
      <SEO />
      <Header frontPage={false} selected="faq" />

      <section className="faqSection">
        <div className="container-fluid">
          <div className="row pt-5 mt-5 ">
            <div className="col align-self-center text-center">
              <Fade>
                <h1 className="primary-text-color">Perguntas Frequentes</h1>
              </Fade>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-sm-2">
              <Image
                alt={"Pergntas Frequentes"}
                className={"img-fluid text-center"}
                name={"faq.png"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-1 "></div>
            <div className="col-md-10 align-self-center text-left p-5">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></div>
            </div>
            <div className="col-md-1 "></div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};
export default IndexPage;
